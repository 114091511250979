unit UTypes;

interface

uses
  System.Generics.Collections
  ;

type
  TFirmenSparte = class
  private
    FId: Integer;
    FBezeichnung: String;

  public
    property Id: Integer read FId write FId;
    property Bezeichnung: String read FBezeichnung write FBezeichnung;
  end;

  TFirmenSparten = TObjectList<TFirmenSparte>;

  TAuswahl = class
  private
    FId: Integer;
    FBezeichnung: String;
  public
    property Id: Integer read FId write FId;
    property Bezeichnung: String read FBezeichnung write FBezeichnung;
  end;

  TAuswahlen = TObjectList<TAuswahl>;

  TFrage = class
  private
    FId: Integer;
    FFrage: String;
    FChecked: Boolean;
  public
    property Id: Integer read FId write FId;
    property Frage: String read FFrage write FFrage;
    property Checked: Boolean read FChecked write FChecked;
  end;

  TFragen = TObjectList<TFrage>;

  TAuswahlFragen = class
  private
    FId: Integer;
    FBezeichnung: String;
    FFragen: TFragen;

  public
    constructor Create;
    destructor Destroy; override;

    function GetFrageById( AId: Integer ): TFrage;

    property Id: Integer read FId write FId;
    property Bezeichnung: String read FBezeichnung write FBezeichnung;
    property Fragen: TFragen read FFragen write FFragen;
  end;

  TFragebogen = class( TObjectList<TAuswahlFragen> )
  public
    function GetFrageByIds( AAuswahlId, AFrageId: Integer ): TFrage;
  end;

  TPerson = class
  private
    FName: String;
    FKontakt: String;
  public
    property Name: String read FName write FName;
    property Kontakt: String read FKontakt write FKontakt;
  end;

  TPersonal = TObjectList<TPerson>;

  TObjekt = class

  end;

implementation

{ TAuswahlFragen }

constructor TAuswahlFragen.Create;
begin
  inherited;

  FFragen := TFragen.Create;
end;

destructor TAuswahlFragen.Destroy;
begin
  FFragen.Free;

  inherited;
end;

function TAuswahlFragen.GetFrageById(AId: Integer): TFrage;
var
  LFrage: TFrage;

begin
  Result := nil;
  for LFrage in Fragen do
  begin
    if LFrage.Id = AId then
    begin
      Result := LFrage;
      Break;
    end;
  end;
end;

{ TFragebogen }

function TFragebogen.GetFrageByIds(AAuswahlId, AFrageId: Integer): TFrage;
var
  LFound,
  LAuswahl: TAuswahlFragen;

begin
  Result := nil;
  LFound := nil;

  // find Auswahl
  for LAuswahl in self do
  begin
    if LAuswahl.Id = AAuswahlId then
    begin
      LFound := LAuswahl;
    end;
  end;

  if Assigned( LFound ) then
  begin
    Result := LFound.GetFrageById( AFrageId );
  end;
end;

{ TPersonal }


end.
