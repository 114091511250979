unit UFrmNewObjekt;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, UDbController, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, UTypes;


type
  TFrmObjektNeu = class(TForm)
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormDOMContentLoaded(Sender: TObject);
  private
    FSelSpartenEl: TJSHTMLSelectElement;
    FAuswahlen: TJSElement;

    FPersonal: TPersonal;

    [async]
    procedure LoadSparten; async;
    procedure UpdateSparten;

    [async]
    procedure LoadAuswahlen( AId: String ); async;
    procedure UpdateAuswahlen;

    function OnSparteSelected( Event: TEventListenerEvent ) : Boolean;
    function OnGenerateFragebogen( Event: TJSMouseEvent ): Boolean;

    procedure HookElements;

    procedure HideAuswahlen;
    procedure ShowAuswahlen;

    procedure HidePersonal;
    procedure ShowPersonal;

    procedure AddPersonal;
    procedure RemovePersonal(AId: Integer);

    procedure UpdateFragebogen;
    procedure UpdatePersonal;

    [async]
    procedure GenerateFragebogen; async;

    function OnButtonClick( Event: TJSMouseEvent ): Boolean;
    function OnBackButtonClick( Event: TJSMouseEvent ): Boolean;
    function OnPersonalAddClick( Event: TJSMouseEvent ): Boolean;
    function OnPersonalDeleteClick( Event: TJSMouseEvent ): Boolean;
  public

  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  UBsAlert
  ;


procedure TFrmObjektNeu.WebFormDestroy(Sender: TObject);
begin
  FPersonal.Free;
end;

procedure TFrmObjektNeu.AddPersonal;
var
  LName,
  LKontakt: TJSHTMLInputElement;

  LPerson: TPerson;

begin
  LName := TJSHTMLInputElement(
    document.querySelector( '#divObjekt #PersonalName' )
  );

  LKontakt := TJSHTMLInputElement(
    document.querySelector( '#divObjekt #PersonalKontakt' )
  );

  if LName.value <> '' then
  begin
    LPerson := TPerson.Create;
    LPerson.Name := LName.value;
    LPerson.Kontakt := LKontakt.value;
    FPersonal.Add( LPerson );

    LKontakt.value := '';
    LName.value := '';

    UpdatePersonal;
  end
  else
  begin
    TBootstrapAlert.Show(
      '#divObjekt #alertPersonal',
      'Name erforderlich.',
      'Bitte geben Sie mindestens einen Namen f&uuml;r weiteres Personal an.'
      );
  end;
end;

procedure TFrmObjektNeu.GenerateFragebogen;
var
  LIds: TJSArray;
  LCheckboxes: TJSNodeList;

  LInput: TJSHTMLInputElement;
  i: Integer;

  LDivSelektion: TJSElement;

begin
  // generate array for ids that are selected

  LCheckBoxes := document.querySelectorAll('#divObjekt .form-check-input');

  LIds := TJSArray.new;

  // check checked state for each checkbox
  for i := 0 to LCheckboxes.length -1 do
  begin
    LInput := TJSHTMLInputElement( LCheckboxes[i] );

    if LInput.checked then
    begin
      LIds.push( StrToInt(LInput.value) );
    end;
  end;

  if LIds.Length > 0 then
  begin
    await( TDbController.Shared.GetFragebogen( LIds ) );

    // hide selection
    LDivSelektion := document.getElementById('divSelektion');
    LDivSelektion.classList.add('collapse');


    UpdateFragebogen;
  end
  else
  begin
    // TODO: Toast that one auswahl has to be made
  end;
end;

procedure TFrmObjektNeu.HideAuswahlen;
begin
  FAuswahlen.classList.add('collapse');
end;

procedure TFrmObjektNeu.HidePersonal;
var
  LPersonalEl: TJSElement;

begin
  LPersonalEl := document.getElementById('tablePersonal');
  LPersonalEl.classList.add('collapse');
end;

procedure TFrmObjektNeu.HookElements;
var
  LButton: TJSHTMLElement;

begin
  FAuswahlen := document.querySelector('#divAuswahlen');
  FSelSpartenEl := TJSHTMLSelectElement( document.querySelector('#selSparten') );

  // back button
  LButton := document.getHTMLElementById('btnBack');
  LButton.onClick := OnBackButtonClick;

  // Personal button
  LButton := document.getHTMLElementById('btnAddPersonal');
  LButton.onClick := OnPersonalAddClick;

  HideAuswahlen;
  HidePersonal;
end;

procedure TFrmObjektNeu.LoadAuswahlen(AId: String);
begin
  await( TDbController.Shared.GetAuswahlen( StrToInt( AId ) ) );

  if TDbController.Shared.Auswahlen.Count > 0 then
  begin
    UpdateAuswahlen;
  end;
end;

procedure TFrmObjektNeu.LoadSparten;
begin
  await( TDbController.Shared.GetFirmenSparten );

  UpdateSparten;
end;

function TFrmObjektNeu.OnBackButtonClick(Event: TJSMouseEvent): Boolean;
begin
  self.Close;
end;

function TFrmObjektNeu.OnButtonClick(Event: TJSMouseEvent): Boolean;
var
  LState: Integer;
  LClickedEl,
  LButtonEl: TJSElement;
  LCaptionEl: TJSElement;
  LFrageId: Integer;
  LAuswahlId: Integer;

  LFrage: TFrage;

begin
  // clicked element can be <i> or <button> !!!
  LClickedEl := Event.targetElement;

  while not (LClickedEl is TJSHTMLButtonElement) do
  begin
    LClickedEl := LClickedEl.parentElement;
  end;

  LButtonEl := LClickedEl;

  LFrageId := StrToInt( LButtonEl.Attrs['data-gp-frage'] );
  LAuswahlId := StrToInt( LButtonEl.Attrs['data-gp-auswahl'] );
  LFrage := TDbController.Shared.Fragebogen.GetFrageByIds( LAuswahlId, LFrageId );

  LCaptionEl := LButtonEl.querySelector('i');

  // get current state
  LState := StrToInt( LButtonEl.Attrs['data-gp-checked'] );

  // flip
  LState := (LState + 1) MOD 2;

  if LState = 0 then
  begin
    LButtonEl.classList.remove('btn-outline-primary');
    LButtonEl.classList.add('btn-outline-secondary');
    LCaptionEl.classList.remove('bi-check2-circle');
    LCaptionEl.classList.add('bi-circle');

    LFrage.Checked := False;
  end
  else
  begin
    LButtonEl.classList.add('btn-outline-primary');
    LButtonEl.classList.remove('btn-outline-secondary');
    LCaptionEl.classList.add('bi-check2-circle');
    LCaptionEl.classList.remove('bi-circle');

    LFrage.Checked := True;
  end;

  LButtonEl.setAttribute('data-gp-checked', LState.ToString);
end;

function TFrmObjektNeu.OnGenerateFragebogen(Event: TJSMouseEvent): Boolean;
begin
  GenerateFragebogen;
end;

function TFrmObjektNeu.OnPersonalAddClick(Event: TJSMouseEvent): Boolean;
begin
  AddPersonal;
end;

function TFrmObjektNeu.OnPersonalDeleteClick(Event: TJSMouseEvent): Boolean;
var
  LIndex: Integer;

begin
  LIndex := StrToInt( Event.targetElement.parentElement.Attrs['data-idx'] );
  RemovePersonal(LIndex);
end;

function TFrmObjektNeu.OnSparteSelected(Event: TEventListenerEvent):
    Boolean;
var
  LIdSparte: String;

begin
  LIdSparte := FSelSpartenEl.value;

  LoadAuswahlen( LIdSparte );
end;

procedure TFrmObjektNeu.RemovePersonal( AId: Integer );
begin
  if (AId < FPersonal.Count) AND (AId > -1 ) then
  begin
    FPersonal.Delete( AId );
    UpdatePersonal;
  end;
end;

procedure TFrmObjektNeu.ShowAuswahlen;
begin
  FAuswahlen.classList.remove('collapse');
end;

procedure TFrmObjektNeu.ShowPersonal;
var
  LPersonalEl: TJSElement;

begin
  LPersonalEl := document.getElementById('tablePersonal');
  LPersonalEl.classList.remove('collapse');
end;

procedure TFrmObjektNeu.UpdateAuswahlen;
var
  LAuswahl: TAuswahl;

  LNewHtml: String;
  LLabel: TJSElement;
  LDiv: TJSHTMLElement;

  LInput: TJSHTMLInputElement;
  LCheckId : String;
  LButton: TJSHTMLButtonElement;

begin
  // clear any content and set header
  FAuswahlen.innerHTML := '<h2 class="fs-5">Bitte w&auml;hlen Sie aus.</h2>';

  // add check for each auswahl
  for LAuswahl in TDbController.Shared.Auswahlen do
  begin
    LDiv := document.createHTMLElement('div');
    LDiv.className := 'form-check';

    LCheckId := 'checkAuswahl' + LAuswahl.Id.ToString;

    LInput := TJSHTMLInputElement( document.createElement('input') );
    LInput.className := 'form-check-input';
    LInput._type := 'checkbox';
    LInput.id := LCheckId;
    LInput.value := LAuswahl.Id.ToString;

    LLabel := document.createElement('label');
    LLabel.className := 'form-check-label';
    LLabel.setAttribute('for', LCheckId );
    LLabel.innerText := LAuswahl.Bezeichnung;

    LDiv.appendChild(LInput);
    LDiv.appendChild(LLabel);

    FAuswahlen.appendChild(LDiv);
  end;

  // finally, add the button to generate fragebogen
  LButton := TJSHTMLButtonElement( document.createElement( 'button' ) );
  LButton.id := 'btnGenerateFragebogen';
  LButton.className := 'btn btn-primary my-4';
  LButton.onclick := OnGenerateFragebogen;
  LButton.innerHTML := 'Fragebogen generieren';

  FAuswahlen.appendChild(LButton);

  ShowAuswahlen;

  // scroll to button
  LButton.scrollIntoView;
end;

procedure TFrmObjektNeu.UpdateFragebogen;
var
  LFragebogenEL: TJSElement;
  LAuswahl: TAuswahlFragen;
  LFrage: TFrage;

  LHeader: TJSElement;
  LButton: TJSHTMLButtonElement;
  LLi,
  LUl : TJSElement;
  LCurrent: Integer;
  LIcon: String;

begin
  LFragebogenEl := document.getElementById('divFragebogen');

  LFragebogenEl.innerHTML := '';

  // TODO: This might be moved into a reusable class as we might want to
  //       edit/view Fragebogen later

  for LAuswahl in TDbController.Shared.Fragebogen do
  begin
    LHeader := document.createElement('h2');
    LHeader.className := 'fs-5 fw-bold mt-3';
    LHeader.innerHTML := '<i class="bi bi-chevron-double-right"></i> ' +LAuswahl.Bezeichnung;
    LFragebogenEl.appendChild(LHeader);

    LUl := document.createElement('ul');
    LUl.className := 'list-group mt-2';

    LCurrent := 0;
    for LFrage in LAuswahl.Fragen do
    begin
      Inc(LCurrent);

      if LCurrent < 11 then
      begin
        LIcon := Format( 'bi-%d-circle', [LCurrent MOD 10] );
      end
      else
      begin
        LIcon := 'bi-caret-right-square';
      end;

      LLi := document.createElement('li');
      LLi.className := 'list-group-item';
      LLi.innerHTML :=
      Format(
      '''
        <div class="d-flex gap-4 align-items-start">
            <span class="fs-2 text-secondary bi %s"></span>
            <p class="fs-5">%s</p>
            <button data-gp-auswahl="%d" data-gp-frage="%d" class="btn btn-outline-secondary ms-auto me-3">
              <i data-gp-index="%d" class="fs-2 bi bi-circle"></i>
            </button>
          </div>
      ''', [ LIcon, LFrage.Frage, LAuswahl.Id, LFrage.Id, LCurrent ] );

      // get button element
      LButton := TJSHTMLButtonElement( LLi.querySelector('button') );
      LButton.setAttribute( 'data-gp-checked', '0' );
      LButton.onclick := OnButtonClick;

      LUl.appendChild(LLi);
    end;

    LFragebogenEl.appendChild(LUl);
  end;
end;

procedure TFrmObjektNeu.UpdatePersonal;
var
  LTable: TJSElement;
  LBody: TJSElement;
  LRowHtml: String;
  LRowEl: TJSElement;

  LPerson: TPerson;
  i: Integer;
  LHtmlEl: TJSHTMLElement;

begin
  if FPersonal.Count = 0 then
  begin
    HidePersonal;
  end
  else
  begin
    LTable := document.querySelector('#tablePersonal');
    LBody := LTable.querySelector('#tbodyPersonal');
    LBody.innerHTML := '';

    LRowHtml :=
    '''
      <td>%s</td>
      <td>%s</td>
      <td data-idx="%d" style="cursor: pointer;"><i class="bi bi-trash"></i></td>
    '''
    ;

    for i := 0 to FPersonal.Count -1 do
    begin
      LPerson := FPersonal[i];

      LRowEl := document.createElement('tr');
      LRowEl.innerHTML :=
        Format(
          LRowHtml,
          [LPerson.Name, LPerson.Kontakt, i]
        );

      LHtmlEl := TJSHTMLElement( LRowEl.querySelector('i') );
      LHtmlEl.onclick := OnPersonalDeleteClick;

      LBody.appendChild(LRowEl);
    end;

    ShowPersonal;
  end;
end;

procedure TFrmObjektNeu.UpdateSparten;
var
  LOption: TJSHTMLOptionElement;
  LSparte: TFirmenSparte;
begin

  FSelSpartenEl.onchange := OnSparteSelected;

  for LSparte in TDbController.Shared.Firmensparten do
  begin
    LOption := TJSHTMLOptionElement.new;
    LOption.text := LSparte.Bezeichnung;
    LOption.value := LSparte.Id.ToString;

    FSelSpartenEl.add(LOption);
  end;
end;

procedure TFrmObjektNeu.WebFormDOMContentLoaded(Sender: TObject);
begin
  FPersonal := TPersonal.Create;

  HookElements;
  LoadSparten;
end;

procedure TFrmObjektNeu.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'FrmObjektNeu';
    Width := 380;
    Height := 258;
    Caption := 'Neuer Fragebogen';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    SetEvent(Self, 'OnDOMContentLoaded', 'WebFormDOMContentLoaded');
  finally
  end;
end;

end.