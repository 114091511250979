unit UFrmLogin;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Storage;

type
  TFrmLogin = class(TForm)
    btnSignin: TButton;
    btnCancel: TButton;
    txtLogin: TEdit;
    txtPassword: TEdit;
    cbRemember: TCheckBox;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);

  strict private
    class var FInstance: TFrmLogin;

  private
    FIsShowing: Boolean;

    function GetLogin: String;
    function GetPassword: String;

  public
    class function Shared: TFrmLogin;
    class destructor Destroy;

    property IsShowing: Boolean read FIsShowing;

    property Login: String read GetLogin;
    property Password: String read GetPassword;

  protected procedure LoadDFMValues; override; end;


implementation

{$R *.dfm}

uses
  UDbController
  ;


{ TFrmLogin }


class destructor TFrmLogin.Destroy;
begin
  FInstance.Free;
end;

function TFrmLogin.GetLogin: String;
begin
  Result := txtLogin.Text;
end;

function TFrmLogin.GetPassword: String;
begin
  Result := txtPassword.Text;
end;

class function TFrmLogin.Shared: TFrmLogin;
begin
  if not Assigned( FInstance ) then
  begin
    FInstance := TFrmLogin.Create(nil);
  end;

  Result := FInstance;
end;

procedure TFrmLogin.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FIsShowing := False;
end;

procedure TFrmLogin.WebFormShow(Sender: TObject);
begin
  FIsShowing := True;
end;

procedure TFrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnSignin := TButton.Create('BtnSignIn');
  btnCancel := TButton.Create('BtnCancel');
  txtLogin := TEdit.Create('Login');
  txtPassword := TEdit.Create('Password');
  cbRemember := TCheckBox.Create('cbRemember');

  btnSignin.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  txtLogin.BeforeLoadDFMValues;
  txtPassword.BeforeLoadDFMValues;
  cbRemember.BeforeLoadDFMValues;
  try
    Name := 'FrmLogin';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    btnSignin.SetParentComponent(Self);
    btnSignin.Name := 'btnSignin';
    btnSignin.Left := 120;
    btnSignin.Top := 72;
    btnSignin.Width := 96;
    btnSignin.Height := 25;
    btnSignin.ElementFont := efCSS;
    btnSignin.HeightStyle := ssAuto;
    btnSignin.HeightPercent := 100.000000000000000000;
    btnSignin.ModalResult := 1;
    btnSignin.WidthPercent := 100.000000000000000000;
    btnCancel.SetParentComponent(Self);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 120;
    btnCancel.Top := 103;
    btnCancel.Width := 96;
    btnCancel.Height := 25;
    btnCancel.ChildOrder := 1;
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.ModalResult := 2;
    btnCancel.WidthPercent := 100.000000000000000000;
    txtLogin.SetParentComponent(Self);
    txtLogin.Name := 'txtLogin';
    txtLogin.Left := 112;
    txtLogin.Top := 160;
    txtLogin.Width := 121;
    txtLogin.Height := 22;
    txtLogin.ChildOrder := 2;
    txtLogin.ElementFont := efCSS;
    txtLogin.HeightStyle := ssAuto;
    txtLogin.HeightPercent := 100.000000000000000000;
    txtLogin.WidthPercent := 100.000000000000000000;
    txtPassword.SetParentComponent(Self);
    txtPassword.Name := 'txtPassword';
    txtPassword.Left := 112;
    txtPassword.Top := 200;
    txtPassword.Width := 121;
    txtPassword.Height := 22;
    txtPassword.ChildOrder := 3;
    txtPassword.ElementFont := efCSS;
    txtPassword.HeightStyle := ssAuto;
    txtPassword.HeightPercent := 100.000000000000000000;
    txtPassword.WidthPercent := 100.000000000000000000;
    cbRemember.SetParentComponent(Self);
    cbRemember.Name := 'cbRemember';
    cbRemember.Left := 328;
    cbRemember.Top := 312;
    cbRemember.Width := 113;
    cbRemember.Height := 22;
    cbRemember.ChildOrder := 4;
    cbRemember.ElementButtonClassName := 'custom-control-input';
    cbRemember.ElementLabelClassName := 'custom-control-label';
    cbRemember.ElementFont := efCSS;
    cbRemember.HeightStyle := ssAuto;
    cbRemember.HeightPercent := 100.000000000000000000;
    cbRemember.WidthPercent := 100.000000000000000000;
  finally
    btnSignin.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    txtLogin.AfterLoadDFMValues;
    txtPassword.AfterLoadDFMValues;
    cbRemember.AfterLoadDFMValues;
  end;
end;

end.
