unit uFrmMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls;

type
  TFrmMain = class(TForm)
    btnObjektNeu: TButton;
    procedure btnObjektNeuClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);

  private
    procedure InitUI;

    procedure OnAuthChanged( IsAuthorized: Boolean );

    [async]
    procedure ShowLogin; async;

    [async]
    procedure ObjektNeu; async;

    procedure Reload;
  public

  protected procedure LoadDFMValues; override; end;

var
  FrmMain: TFrmMain;

implementation

uses
    UDbController
  , UFrmLogin
  , UFrmNewObjekt
  , UTypes
  ;

{$R *.dfm}

procedure TFrmMain.btnObjektNeuClick(Sender: TObject);
begin
  ObjektNeu;
end;

procedure TFrmMain.WebFormCreate(Sender: TObject);
begin
  InitUI;
end;

procedure TFrmMain.ObjektNeu;
var
  LFrm: TFrmObjektNeu;

begin
  LFrm := TFrmObjektNeu.Create(self);
  try
    LFrm.Popup := False;
    
    Await(TFrmObjektNeu,  LFrm.LoadAndExecute );
  finally
    LFrm.Free;
  end;
end;

procedure TFrmMain.InitUI;
begin
  btnObjektNeu.Visible := False;
end;

procedure TFrmMain.OnAuthChanged(IsAuthorized: Boolean);
begin
  if not IsAuthorized then
  begin
    ShowLogin;
  end
  else
  begin
    Reload;
  end;
end;

procedure TFrmMain.Reload;
begin
  btnObjektNeu.Visible := TDbController.Shared.Authorized;
end;

procedure TFrmMain.ShowLogin;
var
  LFrm : TFrmLogin;
  LResult: TModalResult;

begin
  LFrm := TFrmLogin.Create(self);

  try
    LFrm.Popup := False;
    Await(TFrmLogin,  LFrm.Load );
    LResult := Await(TModalResult,  LFrm.Execute );

    if LResult = mrOK then
    begin
      TDbController.Shared.ExecuteLogin( LFrm.Login, LFrm.Password );
    end;
  finally
    LFrm.Free;
  end;

end;

procedure TFrmMain.WebFormShow(Sender: TObject);
begin
  TDbController.Shared.OnAuthorizationChanged := OnAuthChanged;

  TDbController.Shared.Connect;
end;


procedure TFrmMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnObjektNeu := TButton.Create(Self);

  btnObjektNeu.BeforeLoadDFMValues;
  try
    Name := 'FrmMain';
    Width := 640;
    Height := 685;
    Caption := 'Dashboard';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    btnObjektNeu.SetParentComponent(Self);
    btnObjektNeu.Name := 'btnObjektNeu';
    btnObjektNeu.Left := 16;
    btnObjektNeu.Top := 38;
    btnObjektNeu.Width := 273;
    btnObjektNeu.Height := 65;
    btnObjektNeu.Caption := 'Neues Objekt';
    btnObjektNeu.ChildOrder := 5;
    btnObjektNeu.ElementClassName := 'btn btn-primary';
    btnObjektNeu.ElementFont := efCSS;
    btnObjektNeu.HeightStyle := ssAuto;
    btnObjektNeu.HeightPercent := 100.000000000000000000;
    btnObjektNeu.WidthPercent := 100.000000000000000000;
    SetEvent(btnObjektNeu, Self, 'OnClick', 'btnObjektNeuClick');
  finally
    btnObjektNeu.AfterLoadDFMValues;
  end;
end;

end.
