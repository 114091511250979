program gp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  UFrmMain in 'forms\UFrmMain.pas'{*.html},
  UFrmLogin in 'forms\UFrmLogin.pas'{*.html},
  UDbController in 'backend\UDbController.pas',
  UTypes in 'types\UTypes.pas',
  UFrmNewObjekt in 'forms\UFrmNewObjekt.pas'{*.html},
  UBsAlert in 'utils\UBsAlert.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TFrmMain, FrmMain);
  Application.Run;
end.
