unit UBsAlert;

interface

type
  TBootstrapAlert = class

  public
    class procedure Show(
      ACssSelector,
      ATitle,
      AMessage: String;
      AColor: String = 'danger';
      ATimeout: Integer = 3000 );

  end;

implementation

uses
    System.SysUtils
  , Web
  , JS
  ;

{ TBootstrapAlert }

class procedure TBootstrapAlert.Show(ACssSelector, ATitle, AMessage: String;
    AColor: String = 'danger'; ATimeout: Integer = 3000);
var
  LAlert: TJSElement;
  LScript: TJSElement;
  LRoot: TJSElement;
  LName: String;

begin
   LRoot := document.querySelector(ACssSelector);
   LName := 'alert-' + IntToStr( RANDOM(5000) );

   if Assigned( LRoot ) then
   begin
     LAlert := document.createElement('div');
     LAlert.className :=
      Format(
       'alert alert-%s alert-dismissible fade show',
       [ AColor ]
      );

     LAlert.id := LName;
     LAlert.setAttribute('role', 'alert');
     LAlert.innerHTML :=
     Format(
     '''
        <h4 class="fs-4 fw-bold">%s</h4>%s
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
     ''',
     [ ATitle, AMessage ]
     );
     LRoot.appendChild(LAlert);

     if ATimeout <> 0 then
     begin
       LScript := document.createElement('script');
       LScript.textContent :=
       Format(
       '''
          setTimeout(function() {
              var alertElement = document.getElementById('%s');
              if (alertElement) {
                  alertElement.classList.remove('show');
                  alertElement.classList.add('fade');
                  setTimeout(function() {
                      alertElement.remove();
                  }, 150); // Wait for the fade transition to complete
              }
          }, %d);
       ''',
       [ LName, ATimeout ] )
       ;
       LRoot.appendChild(LScript);
     end;
   end
   else
   begin
     {$IFDEF DEBUG}
     console.error('Alert could not be shown. Selector = ' + ACssSelector);
     {$ENDIF}
   end;
end;

end.
