unit uDbController;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules,
  XData.Web.Connection, XData.Web.Client, WEBLib.Storage, Data.DB, WEBLib.DB,
  WEBLib.IndexedDb, UTypes;

type
  TAuthorizationChangedEvent = procedure( IsAuthorized: Boolean ) of object;

type
  TDbController = class(TDataModule)
    Connection: TXDataWebConnection;
    Client: TXDataWebClient;
    procedure ConnectionConnect(Sender: TObject);
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure WebDataModuleCreate(Sender: TObject);

  strict private
    class var FInstance: TDbController;

  private
    { Private declarations }
    FFirmenSparten: TFirmenSparten;
    FAuswahlen: TAuswahlen;

    FAuthorized: Boolean;

//    FOnFirmenspartenReceived: TNotifyEvent;
    FFragebogen: TFragebogen;
    FOnConnected: TNotifyEvent;
    FOnAuthorizationChanged: TAuthorizationChangedEvent;

    function GetConnected: Boolean;

    procedure SetAuthorized( Value: Boolean );

    [async]
    procedure DoAuthorize; async;

  public
    { Public declarations }

    class destructor Destroy;
    class function Shared: TDbController;

    procedure Connect;
    procedure Logout;

    [async]
    procedure ExecuteLogin( ALogin, APassword: String ); async;

    [async]
    procedure GetFirmenSparten; async;

    [async]
    procedure GetAuswahlen( ASparteId: Integer ); async;

    [async]
    procedure GetFragebogen( AIds: TJSArray ); async;

    procedure NotifyConnected;

    property Authorized: Boolean read FAuthorized write SetAuthorized;

    property Connected: Boolean read GetConnected;

    property Firmensparten: TFirmensparten read FFirmensparten;
    property Auswahlen: TAuswahlen read FAuswahlen;
    property Fragebogen: TFragebogen read FFragebogen write FFragebogen;

    property OnConnected: TNotifyEvent read FOnConnected write FOnConnected;
    property OnAuthorizationChanged: TAuthorizationChangedEvent read FOnAuthorizationChanged write FOnAuthorizationChanged;

  protected procedure LoadDFMValues; override; end;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

const
//  BASE_URL = 'http://localhost:8000/services';
  BASE_URL = 'https://beta1.flixengineering.com/services';

procedure TDbController.WebDataModuleCreate(Sender: TObject);
begin
  FOnConnected := nil;
  FOnAuthorizationChanged := nil;

  FFirmenSparten := TFirmenSparten.Create;
  FAuswahlen := TAuswahlen.Create;
  FFragebogen := TFragebogen.Create;
end;

procedure TDbController.Connect;
begin
  FAuthorized := False;
  Connection.URL := BASE_URL;
  Connection.Connected := True;
end;

class destructor TDbController.Destroy;
begin
  FInstance.Free;
end;

procedure TDbController.DoAuthorize;
var
  LResp: TXDataClientResponse;

begin
  try
    LResp := await( Client.RawInvokeAsync(
        'ILoginService.TestToken',
         [] ) );

    Authorized := True;

  except
    Authorized := False;
  end;


end;

procedure TDbController.ConnectionConnect(Sender: TObject);
begin
  NotifyConnected;

  DoAuthorize;
end;

procedure TDbController.WebDataModuleDestroy(Sender: TObject);
begin
  FFirmenSparten.Free;
  FAuswahlen.Free;
  FFragebogen.Free;
end;


procedure TDbController.GetAuswahlen(ASparteId: Integer);
var
  LResp: TXDataClientResponse;
  LArray: TJSArray;
  i: Integer;
  LObj: TJSObject;

  LAuswahl: TAuswahl;

begin
  LResp := await( Client.RawInvokeAsync(
      'IFragebogenservice.Auswahlen',
       [ ASparteId ] ) );

  if LResp.StatusCode = 200 then
  begin
    FAuswahlen.Clear;

    // hole 'value'
    LArray := TJSArray( LResp.ResultAsObject['value'] );

    for i := 0 to LArray.Length - 1 do
    begin
      LObj := TJSObject( LArray[i] );

      LAuswahl := TAuswahl.Create;
      LAuswahl.Id := Integer( LObj['id'] );
      LAuswahl.Bezeichnung := String( LObj['bezeichnung'] );
      FAuswahlen.Add(LAuswahl);
    end;
  end;

end;

function TDbController.GetConnected: Boolean;
begin
  Result := Connection.Connected;
end;

procedure TDbController.GetFirmenSparten;
var
  LResp: TXDataClientResponse;
  LArray: TJSArray;
  i: Integer;
  LObj: TJSObject;

  LSparte: TFirmensparte;

begin
  LResp := await( Client.RawInvokeAsync(
      'IFragebogenservice.Firmensparten',
       [ ] ) );

  if LResp.StatusCode = 200 then
  begin
    FAuswahlen.Clear;
    FFirmensparten.Clear;

    // hole 'value'
    LArray := TJSArray( LResp.ResultAsObject['value'] );

    for i := 0 to LArray.length - 1 do
    begin
      LObj := TJSObject( LArray[i] );

      LSparte := TFirmensparte.Create;
      LSparte.Id := Integer( LObj['id'] );
      LSparte.Bezeichnung := String( LObj['bezeichnung'] );
      FFirmensparten.Add(LSparte);
    end;
  end;
end;

procedure TDbController.GetFragebogen(AIds: TJSArray);
var
  LResp: TXDataClientResponse;
  LArray: TJSArray;
  LObjFrage,
  LObjAuswahl: TJSObject;


  LAuswahl: TAuswahlFragen;

  LArrFragen: TJSArray;
  LFrage: TFrage;
  i, j : Integer;

begin
  LResp := Await(TXDataClientResponse,  Client.RawInvokeAsync(
      'IFragebogenservice.Fragen', [ AIds ] ) );

  if LResp.StatusCode = 200 then
  begin
    FFragebogen.Clear;

    LArray := TJSArray( LResp.ResultAsObject['value'] );
    for i := 0 to LArray.length -1 do
    begin
      LObjAuswahl := TJSObject( LArray[i] );

      LAuswahl := TAuswahlFragen.Create;
      LAuswahl.Id := Integer( LObjAuswahl['id']);
      LAuswahl.Bezeichnung := String( LObjAuswahl['bezeichnung'] );

      LArrFragen := TJSArray( LObjAuswahl['fragen'] );

      for j := 0 to LArrFragen.length - 1 do
      begin
        LObjFrage := TJSObject( LArrFragen[j] );
        LFrage := TFrage.Create;
        LFrage.Id := Integer( LObjFrage['id'] );
        LFrage.Frage := String( LObjFrage['frage'] );

        LAuswahl.Fragen.Add( LFrage );
      end;

      FFragebogen.Add(LAuswahl);
    end;
  end;
end;

procedure TDbController.ExecuteLogin( ALogin, APassword: String );
var
  LResp: TXDataClientResponse;

begin
  // only login from service if token is empty, otherwise ignore

  try
    LResp := await( Client.RawInvokeAsync(
        'ILoginService.Login',
         [ ALogin, APassword ] ) );

    

    if LResp.StatusCode < 400 then
    begin
      Authorized := True;
    end;
  except
    Authorized := False;
  end;



end;

procedure TDbController.Logout;
begin
  // delete the cookie
end;

procedure TDbController.NotifyConnected;
begin
  if Assigned( FOnConnected ) then
  begin
    FOnConnected( Connection );
  end;
end;

procedure TDbController.SetAuthorized( Value: Boolean );
begin
  FAuthorized := Value;

  if Assigned( FOnAuthorizationChanged ) then
  begin
    FOnAuthorizationChanged( Value );
  end;
end;

class function TDbController.Shared: TDbController;
begin
  if not Assigned( FInstance ) then
  begin
    FInstance := TDbController.Create(nil);
  end;

  Result := FInstance;
end;


procedure TDbController.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Connection := TXDataWebConnection.Create(Self);
  Client := TXDataWebClient.Create(Self);

  Connection.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'DbController';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 282;
    Width := 534;
    Connection.SetParentComponent(Self);
    Connection.Name := 'Connection';
    SetEvent(Connection, Self, 'OnConnect', 'ConnectionConnect');
    Connection.Left := 80;
    Connection.Top := 128;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := Connection;
    Client.Left := 80;
    Client.Top := 56;
  finally
    Connection.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
